import React from 'react';
import Layout from '../components/Layout';
import PageHeadSection from '../components/PageHeadSection';
import ContactSection from '../components/ContactSection';
import SEO from '../components/SEO';

function ContactPage(props) {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <PageHeadSection title="Contact Us" />
      <ContactSection
        color="white"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        title=""
        subtitle="Clients often tell us they wish they had contacted us sooner. Contact us today to get started!"
        buttonText="Send message"
        showNameField={true}
        embedSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2727.2959205978086!2d-96.79067642393926!3d46.877226638059604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52c8c967505ec8af%3A0x75a2e94d759de76!2s102%20Broadway%20N%2C%20Fargo%2C%20ND%2058102!5e0!3m2!1sen!2sus!4v1707894136115!5m2!1sen!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"
        data-aos="fade-up"
      />
    </Layout>
  );
}

export default ContactPage;
